/*================ Plyr video skin ================*/

/**
 * Large play button color
 */
.plyr__control--overlaid {
	background: $col--plyr-play;
}

/**
 * Controls hover state color
 */
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
	background: $col--plyr-hover;
}

/**
 * Progress bar color
 */
.plyr--full-ui input[type=range] {
	color: $col--plyr-progress;
}

/**
 * Use site-wide typeface
 */
.plyr {
	@include type--body;
}

/**
 * Hide video until Plyr is initialised
 */
.no-touchevents .video--clicktoplay {
	opacity: 0;

	.plyr & {
		opacity: 1;
	}
}

.video {

	iframe {
		opacity: 0;
	}

	.js-reframe iframe {
		opacity: 1;
	}
}

.plyr {
	opacity: 0;
	animation-name: fadeIn;
	animation-fill-mode: forwards;
	animation-delay: 0s;
	animation-duration: 0.5s;
}

/**
 * Remove black background
 */
.plyr--video,
.plyr__video-wrapper,
.plyr__poster {
	background-color: transparent;
}
