// -----------------------------------------------------------------------------
// Add Dos ✌️ image for site credit.
// -----------------------------------------------------------------------------

/**
 * Format link
 */
.dos-credit__link::after {
	content: '';
	display: inline-block;
	background-image: url('../img/dos.svg');
	background-size: contain;
	background-repeat: no-repeat;
	height: 1.5rem;
	width: 1.5rem;
	position: absolute;
	top: -0.1875rem;
	right: -1.625rem;

	// @include media-query($large-up) {
	// 	height: 2rem;
	// 	width: 2rem;
	// 	top: 0;
	// 	right: -2.125rem;
	// }
}

/**
 * Position credit
 */
.dos-credit {
	padding-top: $spacer-md;
	padding-bottom: $spacer-md;
	padding-left: $spacer-md;

	// @supports(padding: max(0px)) {
	// 	padding-top: unquote('max(#{$spacer-md}, env(safe-area-inset-top))');
	// 	padding-bottom: unquote('max(#{$spacer-md}, env(safe-area-inset-bottom))');
	// 	padding-left: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// }

	@include media-query($medium-up) {
		padding-top: $spacer-lg;
		padding-bottom: $spacer-lg;
		padding-left: $spacer-lg;

		// @supports(padding: max(0px)) {
		// 	padding-top: unquote('max(#{$spacer-lg}, env(safe-area-inset-top))');
		// 	padding-bottom: unquote('max(#{$spacer-lg}, env(safe-area-inset-bottom))');
		// 	padding-left: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// }
	}
}

/**
 * Remove bottom margin from credit
 */
.dos-credit__text {
	margin-bottom: 0;
}

/**
 * Use content to push footer down
 */
.info-page {
	min-height: calc(100vh - 3.5rem);

	@include media-query($medium-up) {
		min-height: calc(100vh - 6rem);
	}
}
