// -----------------------------------------------------------------------------
// This file defines the line heights used within the design system.
// -----------------------------------------------------------------------------

// Line heights
$lh-h0: 1.2;

@mixin line-height--0 {
  line-height: $lh-h0;
}
