/*================ Projects pagination ================*/

/**
 * Horizontal padding
 */
.projects-pagination {
	padding-right: $spacer-md;
	padding-left: $spacer-md;

	// @supports(padding: max(0px)) {
	// 	padding-right: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// 	padding-left: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// }

	@include media-query($medium-up) {
		padding-right: $spacer-lg;
		padding-left: $spacer-lg;

		// @supports(padding: max(0px)) {
		// 	padding-right: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// 	padding-left: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// }
	}
}

/**
 * Vertical padding
 */
.projects-pagination {
	@include p-y-spacer-($spacer-lg);
}

/**
 * Space between image and text
 */
.projects-pagination__cta {
	@include p-t-spacer-($spacer-sm);
}

/**
 * Format links
 */
.projects-pagination__link:hover .faux-link {
	@extend .faux-link-hover;
}

/**
 * Remove margin below text
 */
.projects-pagination__cta,
.projects-pagination__entry-title {
	margin-bottom: 0;
}
