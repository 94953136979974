// -----------------------------------------------------------------------------
// This file defines the fonts used within the type styles.
// -----------------------------------------------------------------------------

@font-face {
	font-family: 'DB Tactic Medium';
	src: url('../fonts/db_tactic-medium-webfont.woff2') format('woff2'),
			 url('../fonts/db_tactic-medium-webfont.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

// 1. Non-webfont to emulate FOUT before fonts are loaded
// 2. Use webfont once it’s loaded
// https://www.bramstein.com/writing/web-font-loading-patterns.html

@mixin font--body {
	font-family: sans-serif; /* 1 */
	font-style: normal;
	font-weight: normal;

	@at-root .font-loaded & {
		font-family: 'DB Tactic Medium', 'Helvetica Neue', 'Helvetica', 'Arial' sans-serif; /* 2 */
	}
}
