// -----------------------------------------------------------------------------
// This file sets the base styles using the design system.
// -----------------------------------------------------------------------------

/**
 * Base site colors
 */
body {
  color: $col--type-dark;
}

/* Highlight color only visible when page overscroll occurs */
html {
  background-color: $col--background-html;
}

/* Main site background-color */
.site-wrapper {
  background-color: $col--background-site-wrapper;
}

/**
 * Prevent horizontal scroll
 */
body {
  overflow-x: hidden;
}

/**
 * Base site typography
 */
body {
  @include type--body;
}

/**
 * Set spacing for typographic elements
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
table {
  margin: #{$lh-h0}em 0; // use base line-height

  &:first-of-type {
    margin-top: 0;;
  }
}

// /**
//  * Prevent bold text from being double bolded
//  */
// strong,
// b {
//   font-weight: inherit;
// }
//
// /**
//  * Prevent italic text from being double italicised
//  */
// em,
// i {
//   font-style: normal;
// }

/**
 * User selected text
 */
::selection {
  background: $col--selection-bg;
  color: $col--selection-text;
}

/**
 * Change base font-size for small and large screens
 * This makes all elements styled with rems increase proportionally
 * 1. Standard, 16px base. Body text is set to 14px from here.
 * 2. This increases our body text style to 18px instead.
 */
html {
 font-size: 100%; /* 1. */
}

@include media-query($widescreen) {
  html {
    font-size: 128.571429%; /* 2. */
  }
}
