/*================ Project ================*/

/**
 * Set outer margins
 */
.project {
	@include outer-margins;
	min-height: 100vh;
}

/**
 * Set width of media and centre it horizontally
 */
.project__media {
	margin: 0 auto;

	@include media-query($large-up) {

		&.project__media--small {
			width: 30vw;
		}

		&.project__media--medium {
			width: 50vw;
		}

		&.project__media--large {
			width: 70vw;
		}
	}
}

/**
 * Set vertical spacing between media
 */
.project__block {
	@include m-b-spacer-($spacer-xl);

	@include media-query($medium-up) {
		@include m-b-spacer-($spacer-xxl);
	}
}

/**
 * Position project title
 */
.project {
	position: relative;
}

.project__title {
	position: fixed;
	z-index: 2;
	bottom: $spacer-md;
	left: $spacer-md;

	// @supports(padding: max(0px)) {
	// 	bottom: unquote('max(#{$spacer-md}, env(safe-area-inset-bottom))');
	// 	left: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// }

	@include media-query($medium-up) {
		bottom: $spacer-lg;
		left: $spacer-lg;

		// @supports(padding: max(0px)) {
		// 	bottom: unquote('max(#{$spacer-lg}, env(safe-area-inset-bottom))');
		// 	left: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// }
	}

	&.is-not-fixed {
		position: absolute;
	}
}

/**
 * Remove margin from project title
 */
.project__title h1 {
	margin-bottom: 0;
}

/**
 * Make gallery full width
 */
.tns-outer {
	margin-right: -$spacer-md;
	margin-left: -$spacer-md;

	@include media-query($medium-up) {
		margin-right: -$spacer-lg;
		margin-left: -$spacer-lg;
	}
}

.gallery__slide .image,
.gallery__slide .video {
	padding-right: $spacer-md;
	padding-left: $spacer-md;

	@include media-query($medium-up) {
		padding-right: $spacer-lg;
		padding-left: $spacer-lg;
	}
}

/**
 * Format gallery counter
 */
.gallery__counter {
	@include m-t-spacer-($spacer-md);
	text-align: center;
}

/**
 * Set cursor on gallery
 */
.gallery {
	cursor: pointer;
}

/**
 * Format text block
 */
.project__text {
	text-align: center;
	max-width: 33.75rem;
	margin: 0 auto;
}

/**
 * Format credits
 */
.credits__toggle {
	@include m-b-spacer-($spacer-md);
}

.credits__content {
	opacity: 1;
	height: auto;

	&.is-hidden {
		opacity: 0;
		height: 0;
	}
}
