/*================ GENERAL RULES USED ACROSS SITES ================*/
/**
 * Apply a natural box layout model to all elements,
 * but allowing components to change
 * https://www.paulirish.com/2012/box-sizing-border-box-ftw/
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

/**
 * Ensure body element fills the page
 */
html,
body {
  height: 100%; }

/**
 * Treat media as block level by default
 */
img,
video {
  display: block; }

/**
 * Ensure media work responsively
 */
img,
video {
  width: 100%; }

/**
 * Ensure text looks good
 */
body,
input,
textarea,
button,
select {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%; }

/**
 * Prevent iOS native styling on form elements
 */
textarea,
input[type='text'],
input[type='button'],
input[type='submit'],
input[type='email'],
input[type='password'] {
  -webkit-appearance: none;
  border-radius: 0; }

/**
 Remove formatting on h tags
 */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: inherit; }

/**
 * Remove italic formatting on address element
 */
address {
  font-style: normal; }

/**
 * Remove margin round figure element
 */
figure {
  margin: 0;
  padding: 0; }

/**
 * Reset padding on lists
 */
ul,
ol {
  padding-left: 1em; }

/**
 * Remove border from iframes
 */
iframe {
  border: none; }

/*================ Helper Classes ================*/
.clearfix {
  *zoom: 1; }
  .clearfix::after {
    content: '';
    display: table;
    clear: both; }

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.hidden {
  display: none; }

.js-focus-hidden:focus {
  outline: none; }

.label-hidden {
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }
  .no-placeholder .label-hidden {
    position: inherit !important;
    overflow: auto;
    clip: auto;
    width: auto;
    height: auto;
    margin: 0; }

.visually-shown {
  position: inherit !important;
  overflow: auto;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0; }

.no-js:not(html) {
  display: none; }
  .no-js .no-js:not(html) {
    display: block; }

.no-js .js {
  display: none; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.bg-left {
  background-position-x: left; }

.bg-center {
  background-position-x: center; }

.bg-right {
  background-position-x: right; }

.bg-top {
  background-position-y: top; }

.bg-middle {
  background-position-y: center; }

.bg-bottom {
  background-position-y: bottom; }

.disabled {
  opacity: 0.5; }

a.disabled {
  cursor: not-allowed; }

.no-scroll {
  overflow: hidden; }

.clear-left {
  clear: left; }

.clear-right {
  clear: right; }

.clear-both {
  clear: both; }

/* No focus for non tabbers */
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none; }

/* a subtle focus style for keyboard-input elements */
.text-input:focus {
  outline: 1px solid #aaa;
  /* Adjust to suit your tastes */ }

/* no outline for non-keyboard-inputs elements */
button:focus,
select:focus {
  outline: none; }

/* add focus for keyboard users */
html.user-is-tabbing *:focus {
  outline: 2px solid #7AACFE !important;
  /* for non-webkit browsers */
  outline: 5px auto -webkit-focus-ring-color !important; }

/*================ VENDOR ================*/
/*================ DESIGN SYSTEM ================*/
@font-face {
  font-family: 'DB Tactic Medium';
  src: url("../fonts/db_tactic-medium-webfont.woff2") format("woff2"), url("../fonts/db_tactic-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

.m-t-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem; }

.m-b-spacer-xs 0.3125rem {
  margin-bottom: sm 0.625rem; }

.m-l-spacer-xs 0.3125rem {
  margin-left: sm 0.625rem; }

.m-r-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem; }

.m-x-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem;
  margin-left: sm 0.625rem; }

.m-y-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem;
  margin-bottom: sm 0.625rem; }

.m-t-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem; }

.m-b-spacer-xs 0.3125rem {
  margin-bottom: sm 0.625rem; }

.m-l-spacer-xs 0.3125rem {
  margin-left: sm 0.625rem; }

.m-r-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem; }

.m-x-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem;
  margin-left: sm 0.625rem; }

.m-y-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem;
  margin-bottom: sm 0.625rem; }

.m-t-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem; }

.m-b-spacer-xs 0.3125rem {
  margin-bottom: sm 0.625rem; }

.m-l-spacer-xs 0.3125rem {
  margin-left: sm 0.625rem; }

.m-r-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem; }

.m-x-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem;
  margin-left: sm 0.625rem; }

.m-y-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem;
  margin-bottom: sm 0.625rem; }

.m-t-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem; }

.m-b-spacer-xs 0.3125rem {
  margin-bottom: sm 0.625rem; }

.m-l-spacer-xs 0.3125rem {
  margin-left: sm 0.625rem; }

.m-r-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem; }

.m-x-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem;
  margin-left: sm 0.625rem; }

.m-y-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem;
  margin-bottom: sm 0.625rem; }

.m-t-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem; }

.m-b-spacer-xs 0.3125rem {
  margin-bottom: sm 0.625rem; }

.m-l-spacer-xs 0.3125rem {
  margin-left: sm 0.625rem; }

.m-r-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem; }

.m-x-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem;
  margin-left: sm 0.625rem; }

.m-y-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem;
  margin-bottom: sm 0.625rem; }

.m-t-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem; }

.m-b-spacer-xs 0.3125rem {
  margin-bottom: sm 0.625rem; }

.m-l-spacer-xs 0.3125rem {
  margin-left: sm 0.625rem; }

.m-r-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem; }

.m-x-spacer-xs 0.3125rem {
  margin-right: sm 0.625rem;
  margin-left: sm 0.625rem; }

.m-y-spacer-xs 0.3125rem {
  margin-top: sm 0.625rem;
  margin-bottom: sm 0.625rem; }

.p-t-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem; }

.p-b-spacer-xs 0.3125rem {
  padding-bottom: sm 0.625rem; }

.p-l-spacer-xs 0.3125rem {
  padding-left: sm 0.625rem; }

.p-r-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem; }

.p-x-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem;
  padding-left: sm 0.625rem; }

.p-y-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem;
  padding-bottom: sm 0.625rem; }

.p-t-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem; }

.p-b-spacer-xs 0.3125rem {
  padding-bottom: sm 0.625rem; }

.p-l-spacer-xs 0.3125rem {
  padding-left: sm 0.625rem; }

.p-r-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem; }

.p-x-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem;
  padding-left: sm 0.625rem; }

.p-y-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem;
  padding-bottom: sm 0.625rem; }

.p-t-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem; }

.p-b-spacer-xs 0.3125rem {
  padding-bottom: sm 0.625rem; }

.p-l-spacer-xs 0.3125rem {
  padding-left: sm 0.625rem; }

.p-r-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem; }

.p-x-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem;
  padding-left: sm 0.625rem; }

.p-y-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem;
  padding-bottom: sm 0.625rem; }

.p-t-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem; }

.p-b-spacer-xs 0.3125rem {
  padding-bottom: sm 0.625rem; }

.p-l-spacer-xs 0.3125rem {
  padding-left: sm 0.625rem; }

.p-r-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem; }

.p-x-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem;
  padding-left: sm 0.625rem; }

.p-y-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem;
  padding-bottom: sm 0.625rem; }

.p-t-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem; }

.p-b-spacer-xs 0.3125rem {
  padding-bottom: sm 0.625rem; }

.p-l-spacer-xs 0.3125rem {
  padding-left: sm 0.625rem; }

.p-r-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem; }

.p-x-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem;
  padding-left: sm 0.625rem; }

.p-y-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem;
  padding-bottom: sm 0.625rem; }

.p-t-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem; }

.p-b-spacer-xs 0.3125rem {
  padding-bottom: sm 0.625rem; }

.p-l-spacer-xs 0.3125rem {
  padding-left: sm 0.625rem; }

.p-r-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem; }

.p-x-spacer-xs 0.3125rem {
  padding-right: sm 0.625rem;
  padding-left: sm 0.625rem; }

.p-y-spacer-xs 0.3125rem {
  padding-top: sm 0.625rem;
  padding-bottom: sm 0.625rem; }

/*============================================================================
  Generate breakpoint-specific column widths and push classes
    - Default column widths: $breakpoint-has-widths: ($small, $medium-up);
    - Default is no push classes
    - Will not work if `styles/global/grid.scss` is removed
==============================================================================*/
/*============================================================================
  Grid
    - Based on CSS Wizardry grid
==============================================================================*/
.grid {
  *zoom: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: -1.25rem; }
  .grid::after {
    content: '';
    display: table;
    clear: both; }

.grid__item {
  float: left;
  padding-left: 1.25rem;
  width: 100%; }
  .grid__item[class*='--push'] {
    position: relative; }

@media only screen and (min-width: 540px) {
  .grid {
    margin-left: -2.5rem; }
  .grid__item {
    padding-left: 2.5rem; } }

/*============================================================================
  Reversed grids allow you to structure your source in the opposite
  order to how your rendered layout will appear.
==============================================================================*/
.grid--rev {
  direction: rtl;
  text-align: left; }
  .grid--rev > .grid__item {
    direction: ltr;
    text-align: left;
    float: right; }

/*============================================================================
  Grid Columns
    - Create width classes, prepended by the breakpoint name.
==============================================================================*/
/*================ Grid push classes ================*/
/*================ Clearfix helper on uniform grids ================*/
/*================ Build Base Grid Classes ================*/
/** Whole */
.one-whole {
  width: 100%; }

/* Halves */
.one-half {
  width: 50%; }

/* Thirds */
.one-third {
  width: 33.33333%; }

.two-thirds {
  width: 66.66667%; }

/* Quarters */
.one-quarter {
  width: 25%; }

.two-quarters {
  width: 50%; }

.three-quarters {
  width: 75%; }

/* Fifths */
.one-fifth {
  width: 20%; }

.two-fifths {
  width: 40%; }

.three-fifths {
  width: 60%; }

.four-fifths {
  width: 80%; }

/* Sixths */
.one-sixth {
  width: 16.66667%; }

.two-sixths {
  width: 33.33333%; }

.three-sixths {
  width: 50%; }

.four-sixths {
  width: 66.66667%; }

.five-sixths {
  width: 83.33333%; }

/* Sevenths */
.one-seventh {
  width: 14.28571%; }

.two-sevenths {
  width: 28.57143%; }

.three-sevenths {
  width: 42.85714%; }

.four-sevenths {
  width: 57.14286%; }

.five-sevenths {
  width: 71.42857%; }

.six-sevenths {
  width: 85.71429%; }

/* Eighths */
.one-eighth {
  width: 12.5%; }

.two-eighths {
  width: 25%; }

.three-eighths {
  width: 37.5%; }

.four-eighths {
  width: 50%; }

.five-eighths {
  width: 62.5%; }

.six-eighths {
  width: 75%; }

.seven-eighths {
  width: 87.5%; }

/* Ninths */
.one-ninth {
  width: 11.11111%; }

.two-ninths {
  width: 22.22222%; }

.three-ninths {
  width: 33.33333%; }

.four-ninths {
  width: 44.44444%; }

.five-ninths {
  width: 55.55556%; }

.six-ninths {
  width: 66.66667%; }

.seven-ninths {
  width: 77.77778%; }

.eight-ninths {
  width: 88.88889%; }

/* Tenths */
.one-tenth {
  width: 10%; }

.two-tenths {
  width: 20%; }

.three-tenths {
  width: 30%; }

.four-tenths {
  width: 40%; }

.five-tenths {
  width: 50%; }

.six-tenths {
  width: 60%; }

.seven-tenths {
  width: 70%; }

.eight-tenths {
  width: 80%; }

.nine-tenths {
  width: 90%; }

/* Twelfths */
.one-twelfth {
  width: 8.33333%; }

.two-twelfths {
  width: 16.66667%; }

.three-twelfths {
  width: 25%; }

.four-twelfths {
  width: 33.33333%; }

.five-twelfths {
  width: 41.66667%; }

.six-twelfths {
  width: 50%; }

.seven-twelfths {
  width: 58.33333%; }

.eight-twelfths {
  width: 66.66667%; }

.nine-twelfths {
  width: 75%; }

.ten-twelfths {
  width: 83.33333%; }

.eleven-twelfths {
  width: 91.66667%; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

/*================ Build Responsive Grid Classes ================*/
@media only screen and (min-width: 540px) {
  /** Whole */
  .medium-up--one-whole {
    width: 100%; }
  /* Halves */
  .medium-up--one-half {
    width: 50%; }
  /* Thirds */
  .medium-up--one-third {
    width: 33.33333%; }
  .medium-up--two-thirds {
    width: 66.66667%; }
  /* Quarters */
  .medium-up--one-quarter {
    width: 25%; }
  .medium-up--two-quarters {
    width: 50%; }
  .medium-up--three-quarters {
    width: 75%; }
  /* Fifths */
  .medium-up--one-fifth {
    width: 20%; }
  .medium-up--two-fifths {
    width: 40%; }
  .medium-up--three-fifths {
    width: 60%; }
  .medium-up--four-fifths {
    width: 80%; }
  /* Sixths */
  .medium-up--one-sixth {
    width: 16.66667%; }
  .medium-up--two-sixths {
    width: 33.33333%; }
  .medium-up--three-sixths {
    width: 50%; }
  .medium-up--four-sixths {
    width: 66.66667%; }
  .medium-up--five-sixths {
    width: 83.33333%; }
  /* Sevenths */
  .medium-up--one-seventh {
    width: 14.28571%; }
  .medium-up--two-sevenths {
    width: 28.57143%; }
  .medium-up--three-sevenths {
    width: 42.85714%; }
  .medium-up--four-sevenths {
    width: 57.14286%; }
  .medium-up--five-sevenths {
    width: 71.42857%; }
  .medium-up--six-sevenths {
    width: 85.71429%; }
  /* Eighths */
  .medium-up--one-eighth {
    width: 12.5%; }
  .medium-up--two-eighths {
    width: 25%; }
  .medium-up--three-eighths {
    width: 37.5%; }
  .medium-up--four-eighths {
    width: 50%; }
  .medium-up--five-eighths {
    width: 62.5%; }
  .medium-up--six-eighths {
    width: 75%; }
  .medium-up--seven-eighths {
    width: 87.5%; }
  /* Ninths */
  .medium-up--one-ninth {
    width: 11.11111%; }
  .medium-up--two-ninths {
    width: 22.22222%; }
  .medium-up--three-ninths {
    width: 33.33333%; }
  .medium-up--four-ninths {
    width: 44.44444%; }
  .medium-up--five-ninths {
    width: 55.55556%; }
  .medium-up--six-ninths {
    width: 66.66667%; }
  .medium-up--seven-ninths {
    width: 77.77778%; }
  .medium-up--eight-ninths {
    width: 88.88889%; }
  /* Tenths */
  .medium-up--one-tenth {
    width: 10%; }
  .medium-up--two-tenths {
    width: 20%; }
  .medium-up--three-tenths {
    width: 30%; }
  .medium-up--four-tenths {
    width: 40%; }
  .medium-up--five-tenths {
    width: 50%; }
  .medium-up--six-tenths {
    width: 60%; }
  .medium-up--seven-tenths {
    width: 70%; }
  .medium-up--eight-tenths {
    width: 80%; }
  .medium-up--nine-tenths {
    width: 90%; }
  /* Twelfths */
  .medium-up--one-twelfth {
    width: 8.33333%; }
  .medium-up--two-twelfths {
    width: 16.66667%; }
  .medium-up--three-twelfths {
    width: 25%; }
  .medium-up--four-twelfths {
    width: 33.33333%; }
  .medium-up--five-twelfths {
    width: 41.66667%; }
  .medium-up--six-twelfths {
    width: 50%; }
  .medium-up--seven-twelfths {
    width: 58.33333%; }
  .medium-up--eight-twelfths {
    width: 66.66667%; }
  .medium-up--nine-twelfths {
    width: 75%; }
  .medium-up--ten-twelfths {
    width: 83.33333%; }
  .medium-up--eleven-twelfths {
    width: 91.66667%; }
  .grid--uniform .medium-up--one-half:nth-child(2n+1),
  .grid--uniform .medium-up--one-third:nth-child(3n+1),
  .grid--uniform .medium-up--one-quarter:nth-child(4n+1),
  .grid--uniform .medium-up--one-fifth:nth-child(5n+1),
  .grid--uniform .medium-up--one-sixth:nth-child(6n+1),
  .grid--uniform .medium-up--two-sixths:nth-child(3n+1),
  .grid--uniform .medium-up--three-sixths:nth-child(2n+1),
  .grid--uniform .medium-up--two-sevenths:nth-child(3n+1),
  .grid--uniform .medium-up--three-sevenths:nth-child(2n+1),
  .grid--uniform .medium-up--one-eighth:nth-child(8n+1),
  .grid--uniform .medium-up--two-eighths:nth-child(4n+1),
  .grid--uniform .medium-up--four-eighths:nth-child(2n+1),
  .grid--uniform .medium-up--five-tenths:nth-child(2n+1),
  .grid--uniform .medium-up--one-twelfth:nth-child(12n+1),
  .grid--uniform .medium-up--two-twelfths:nth-child(6n+1),
  .grid--uniform .medium-up--three-twelfths:nth-child(4n+1),
  .grid--uniform .medium-up--four-twelfths:nth-child(3n+1),
  .grid--uniform .medium-up--six-twelfths:nth-child(2n+1) {
    clear: both; }
  .medium-up--show {
    display: block !important; }
  .medium-up--hide {
    display: none !important; }
  .medium-up--text-left {
    text-align: left !important; }
  .medium-up--text-right {
    text-align: right !important; }
  .medium-up--text-center {
    text-align: center !important; } }

@media only screen and (min-width: 990px) {
  /** Whole */
  .large-up--one-whole {
    width: 100%; }
  /* Halves */
  .large-up--one-half {
    width: 50%; }
  /* Thirds */
  .large-up--one-third {
    width: 33.33333%; }
  .large-up--two-thirds {
    width: 66.66667%; }
  /* Quarters */
  .large-up--one-quarter {
    width: 25%; }
  .large-up--two-quarters {
    width: 50%; }
  .large-up--three-quarters {
    width: 75%; }
  /* Fifths */
  .large-up--one-fifth {
    width: 20%; }
  .large-up--two-fifths {
    width: 40%; }
  .large-up--three-fifths {
    width: 60%; }
  .large-up--four-fifths {
    width: 80%; }
  /* Sixths */
  .large-up--one-sixth {
    width: 16.66667%; }
  .large-up--two-sixths {
    width: 33.33333%; }
  .large-up--three-sixths {
    width: 50%; }
  .large-up--four-sixths {
    width: 66.66667%; }
  .large-up--five-sixths {
    width: 83.33333%; }
  /* Sevenths */
  .large-up--one-seventh {
    width: 14.28571%; }
  .large-up--two-sevenths {
    width: 28.57143%; }
  .large-up--three-sevenths {
    width: 42.85714%; }
  .large-up--four-sevenths {
    width: 57.14286%; }
  .large-up--five-sevenths {
    width: 71.42857%; }
  .large-up--six-sevenths {
    width: 85.71429%; }
  /* Eighths */
  .large-up--one-eighth {
    width: 12.5%; }
  .large-up--two-eighths {
    width: 25%; }
  .large-up--three-eighths {
    width: 37.5%; }
  .large-up--four-eighths {
    width: 50%; }
  .large-up--five-eighths {
    width: 62.5%; }
  .large-up--six-eighths {
    width: 75%; }
  .large-up--seven-eighths {
    width: 87.5%; }
  /* Ninths */
  .large-up--one-ninth {
    width: 11.11111%; }
  .large-up--two-ninths {
    width: 22.22222%; }
  .large-up--three-ninths {
    width: 33.33333%; }
  .large-up--four-ninths {
    width: 44.44444%; }
  .large-up--five-ninths {
    width: 55.55556%; }
  .large-up--six-ninths {
    width: 66.66667%; }
  .large-up--seven-ninths {
    width: 77.77778%; }
  .large-up--eight-ninths {
    width: 88.88889%; }
  /* Tenths */
  .large-up--one-tenth {
    width: 10%; }
  .large-up--two-tenths {
    width: 20%; }
  .large-up--three-tenths {
    width: 30%; }
  .large-up--four-tenths {
    width: 40%; }
  .large-up--five-tenths {
    width: 50%; }
  .large-up--six-tenths {
    width: 60%; }
  .large-up--seven-tenths {
    width: 70%; }
  .large-up--eight-tenths {
    width: 80%; }
  .large-up--nine-tenths {
    width: 90%; }
  /* Twelfths */
  .large-up--one-twelfth {
    width: 8.33333%; }
  .large-up--two-twelfths {
    width: 16.66667%; }
  .large-up--three-twelfths {
    width: 25%; }
  .large-up--four-twelfths {
    width: 33.33333%; }
  .large-up--five-twelfths {
    width: 41.66667%; }
  .large-up--six-twelfths {
    width: 50%; }
  .large-up--seven-twelfths {
    width: 58.33333%; }
  .large-up--eight-twelfths {
    width: 66.66667%; }
  .large-up--nine-twelfths {
    width: 75%; }
  .large-up--ten-twelfths {
    width: 83.33333%; }
  .large-up--eleven-twelfths {
    width: 91.66667%; }
  .grid--uniform .large-up--one-half:nth-child(2n+1),
  .grid--uniform .large-up--one-third:nth-child(3n+1),
  .grid--uniform .large-up--one-quarter:nth-child(4n+1),
  .grid--uniform .large-up--one-fifth:nth-child(5n+1),
  .grid--uniform .large-up--one-sixth:nth-child(6n+1),
  .grid--uniform .large-up--two-sixths:nth-child(3n+1),
  .grid--uniform .large-up--three-sixths:nth-child(2n+1),
  .grid--uniform .large-up--two-sevenths:nth-child(3n+1),
  .grid--uniform .large-up--three-sevenths:nth-child(2n+1),
  .grid--uniform .large-up--one-eighth:nth-child(8n+1),
  .grid--uniform .large-up--two-eighths:nth-child(4n+1),
  .grid--uniform .large-up--four-eighths:nth-child(2n+1),
  .grid--uniform .large-up--five-tenths:nth-child(2n+1),
  .grid--uniform .large-up--one-twelfth:nth-child(12n+1),
  .grid--uniform .large-up--two-twelfths:nth-child(6n+1),
  .grid--uniform .large-up--three-twelfths:nth-child(4n+1),
  .grid--uniform .large-up--four-twelfths:nth-child(3n+1),
  .grid--uniform .large-up--six-twelfths:nth-child(2n+1) {
    clear: both; }
  .large-up--show {
    display: block !important; }
  .large-up--hide {
    display: none !important; }
  .large-up--text-left {
    text-align: left !important; }
  .large-up--text-right {
    text-align: right !important; }
  .large-up--text-center {
    text-align: center !important; } }

/*================ Build Grid Push Classes ================*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

/*================ GLOBALS ================*/
/**
 * Base site colors
 */
body {
  color: #000000; }

/* Highlight color only visible when page overscroll occurs */
html {
  background-color: #FFFFFF; }

/* Main site background-color */
.site-wrapper {
  background-color: #FFFFFF; }

/**
 * Prevent horizontal scroll
 */
body {
  overflow-x: hidden; }

/**
 * Base site typography
 */
body {
  font-family: sans-serif;
  /* 1 */
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.2; }
  .font-loaded body {
    font-family: 'DB Tactic Medium', 'Helvetica Neue', 'Helvetica', 'Arial' sans-serif;
    /* 2 */ }

/**
 * Set spacing for typographic elements
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
blockquote,
table {
  margin: 1.2em 0; }
  h1:first-of-type,
  h2:first-of-type,
  h3:first-of-type,
  h4:first-of-type,
  h5:first-of-type,
  h6:first-of-type,
  p:first-of-type,
  ul:first-of-type,
  ol:first-of-type,
  blockquote:first-of-type,
  table:first-of-type {
    margin-top: 0; }

/**
 * User selected text
 */
::selection {
  background: #000000;
  color: #FFFFFF; }

/**
 * Change base font-size for small and large screens
 * This makes all elements styled with rems increase proportionally
 * 1. Standard, 16px base. Body text is set to 14px from here.
 * 2. This increases our body text style to 18px instead.
 */
html {
  font-size: 100%;
  /* 1. */ }

@media only screen and (min-width: 1800px) {
  html {
    font-size: 128.571429%;
    /* 2. */ } }

/*================ Layout ================*/
/**
 * Min height
 */
.site-inner-wrapper {
  min-height: 100vh; }

/**
 * Prevent overflow
 */
.site-inner-wrapper {
  width: 100vw;
  overflow: hidden; }

/*================ Transitions ================*/
/**
 * Define initial load transition
 */
.new-session .site-header,
.new-session .page-content {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-duration: 0.5s; }

/**
 * Define between pages transition
 */
.fade-in .page-content {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;
  animation-duration: 0.5s; }

.fade-out .page-content {
  animation-name: fadeOut !important;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  animation-duration: 0.5s; }

/*================ Links ================*/
/**
 * Link formatting
 */
a,
a:link,
a:visited,
a:active,
a:focus,
.faux-link {
  text-decoration: none;
  background-position: 0 1em;
  background-size: 100% 0.125rem;
  background-repeat: no-repeat;
  background-image: linear-gradient(transparent 50%, rgba(0, 0, 0, 0.25) 50%);
  color: #000000;
  cursor: pointer; }

a:hover,
.faux-link:hover,
.faux-link-hover,
.selected-projects__project-link:hover .faux-link,
.archive__project-link:hover .faux-link,
.view-archive__link:hover .faux-link,
.projects-pagination__link:hover .faux-link {
  background-image: linear-gradient(transparent 50%, #000000 50%);
  color: #000000; }

/**
 * Add click effect
 */
a {
  position: relative;
  top: 0; }
  a:active {
    top: 0.0625rem; }

/**
 * Remove underline
 */
.link-no-underline, .link-no-underline:link, .link-no-underline:visited, .link-no-underline:hover, .link-no-underline:active, .link-no-underline:focus {
  background-image: none !important; }

/*================ MODULES ================*/
/*================ Blank  ================*/
/**
 * Set outer margins
 */
[data-namespace="blank"] .page-content {
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  @media only screen and (min-width: 540px) {
    [data-namespace="blank"] .page-content {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

/*================ Site header ================*/
/**
 * Positioning
 */
.site-header {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 2;
  top: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .site-header {
      top: 2.5rem; } }

.site-header__name {
  position: absolute;
  left: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .site-header__name {
      left: 2.5rem; } }

.site-header__nav {
  position: absolute;
  right: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .site-header__nav {
      right: 2.5rem; } }

/**
 * Increase hit area on links
 */
.site-header__link {
  padding-top: 1em;
  padding-bottom: 0.5em;
  background-position: 0 2em !important; }

/**
 * Remove bottom margins
 */
.site-header h1,
.site-header p {
  margin-bottom: 0; }

/**
 * Format list
 */
.site-header__nav-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.site-header__nav-list-item {
  display: inline;
  margin-left: 0.75rem; }
  .site-header__nav-list-item:first-of-type {
    margin-left: 0; }

/*================ Text pages ================*/
/**
 * Set outer margins
 */
.text-page {
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .text-page {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

/**
 * Format text
 */
.text-page {
  max-width: 33.75rem; }

/*================ Homepage ================*/
/**
 * Format selected projects container
 */
.selected-projects {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

/**
 * Format project slides
 */
.selected-projects__project {
  height: 100%; }

.selected-projects__project-link {
  display: block;
  position: relative;
  height: 100%;
  width: 100%; }

/**
 * Set size of media
 */
.selected-projects__project-media > .image,
.selected-projects__project-media > .video {
  height: auto;
  width: calc(100vw - (1.25rem * 2));
  max-height: 80vh; }

@media only screen and (min-width: 540px) {
  .selected-projects__project-media--small > .image,
  .selected-projects__project-media--small > .video {
    width: 30vw; }
  .selected-projects__project-media--medium > .image,
  .selected-projects__project-media--medium > .video {
    width: 50vw; }
  .selected-projects__project-media--large > .image,
  .selected-projects__project-media--large > .video {
    width: 70vw; } }

/**
 * Centre the slides
 */
.selected-projects__project-media > .image,
.selected-projects__project-media > .video {
  position: absolute;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%); }

/**
 * Position project text
 */
.selected-projects__project-info {
  position: absolute;
  z-index: 2;
  width: calc(100% - (1.25rem * 2));
  bottom: 1.25rem;
  left: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .selected-projects__project-info {
      bottom: 2.5rem;
      left: 2.5rem; } }

/**
 * Remove margins from project text
 */
.selected-projects__project-title,
.selected-projects__project-cta {
  margin-bottom: 0; }

/**
 * Format faux link hover state
 */
/**
 * Remove link flash from iOS browsers
 */
.selected-projects__project-link {
  -webkit-tap-highlight-color: transparent; }

/*================ Project ================*/
/**
 * Set outer margins
 */
.project {
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  min-height: 100vh; }
  @media only screen and (min-width: 540px) {
    .project {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

/**
 * Set width of media and centre it horizontally
 */
.project__media {
  margin: 0 auto; }
  @media only screen and (min-width: 990px) {
    .project__media.project__media--small {
      width: 30vw; }
    .project__media.project__media--medium {
      width: 50vw; }
    .project__media.project__media--large {
      width: 70vw; } }

/**
 * Set vertical spacing between media
 */
.project__block {
  margin-bottom: 5rem; }
  @media only screen and (min-width: 540px) {
    .project__block {
      margin-bottom: 10rem; } }

/**
 * Position project title
 */
.project {
  position: relative; }

.project__title {
  position: fixed;
  z-index: 2;
  bottom: 1.25rem;
  left: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .project__title {
      bottom: 2.5rem;
      left: 2.5rem; } }
  .project__title.is-not-fixed {
    position: absolute; }

/**
 * Remove margin from project title
 */
.project__title h1 {
  margin-bottom: 0; }

/**
 * Make gallery full width
 */
.tns-outer {
  margin-right: -1.25rem;
  margin-left: -1.25rem; }
  @media only screen and (min-width: 540px) {
    .tns-outer {
      margin-right: -2.5rem;
      margin-left: -2.5rem; } }

.gallery__slide .image,
.gallery__slide .video {
  padding-right: 1.25rem;
  padding-left: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .gallery__slide .image,
    .gallery__slide .video {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }

/**
 * Format gallery counter
 */
.gallery__counter {
  margin-top: 1.25rem;
  text-align: center; }

/**
 * Set cursor on gallery
 */
.gallery {
  cursor: pointer; }

/**
 * Format text block
 */
.project__text {
  text-align: center;
  max-width: 33.75rem;
  margin: 0 auto; }

/**
 * Format credits
 */
.credits__toggle {
  margin-bottom: 1.25rem; }

.credits__content {
  opacity: 1;
  height: auto; }
  .credits__content.is-hidden {
    opacity: 0;
    height: 0; }

/*================ All projects  ================*/
/**
 * Set outer margins
 */
.project-index-page {
  padding-top: 10rem;
  padding-bottom: 10rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .project-index-page {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

/**
 * Set background color
 */
.project-index-page {
  background-color: #FFFFFF; }

/**
 * Sort clearing of rows
 */
.archive__project:nth-child(2n+1) {
  clear: both; }

@media only screen and (min-width: 540px) {
  .archive__project:nth-child(2n+1) {
    clear: none; }
  .archive__project:nth-child(3n+1) {
    clear: both; } }

@media only screen and (min-width: 990px) {
  .archive__project:nth-child(3n+1) {
    clear: none; }
  .archive__project:nth-child(4n+1) {
    clear: both; } }

/**
 * Space before project title
 */
.archive__project-title {
  padding-top: 0.625rem; }

/**
 * Space between rows
 */
.archive__project {
  margin-bottom: 1.25rem; }
  @media only screen and (min-width: 990px) {
    .archive__project {
      margin-bottom: 2.5rem; } }

/**
 * Format faux link hover state
 */
/**
 * Layout text only grid
 */
.archive--text-only {
  padding-top: 2.5rem;
  padding-bottom: 0;
  padding-left: 1.25rem;
  padding-right: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .archive--text-only {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }

/*================ View all projects link ================*/
/**
 * Horizontal padding
 */
.view-archive__link {
  padding-right: 1.25rem;
  padding-left: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .view-archive__link {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }

/**
 * Vertical padding
 */
.view-archive__link {
  padding-top: 5rem;
  padding-bottom: 5rem; }

/**
 * Make link cover the full area
 */
.view-archive__link {
  display: block; }

/**
 * Format faux link hover state
 */
/**
 * Set hover state on whole link
 */
.view-archive__link:hover {
  transition: background-color 0.2s ease;
  background-color: rgba(0, 0, 0, 0.03); }

/*================ Projects pagination ================*/
/**
 * Horizontal padding
 */
.projects-pagination {
  padding-right: 1.25rem;
  padding-left: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .projects-pagination {
      padding-right: 2.5rem;
      padding-left: 2.5rem; } }

/**
 * Vertical padding
 */
.projects-pagination {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

/**
 * Space between image and text
 */
.projects-pagination__cta {
  padding-top: 0.625rem; }

/**
 * Format links
 */
/**
 * Remove margin below text
 */
.projects-pagination__cta,
.projects-pagination__entry-title {
  margin-bottom: 0; }

/*================ Text color based on bg color ================*/
/**
 * Set text color
 */
.text--light .site-header,
.text--light .project,
.text--light .selected-projects__project {
  color: #FFFFFF; }
  .text--light .site-header a,
  .text--light .site-header a:link,
  .text--light .site-header a:visited,
  .text--light .site-header a:active,
  .text--light .site-header a:focus,
  .text--light .site-header .faux-link,
  .text--light .project a,
  .text--light .project a:link,
  .text--light .project a:visited,
  .text--light .project a:active,
  .text--light .project a:focus,
  .text--light .project .faux-link,
  .text--light .selected-projects__project a,
  .text--light .selected-projects__project a:link,
  .text--light .selected-projects__project a:visited,
  .text--light .selected-projects__project a:active,
  .text--light .selected-projects__project a:focus,
  .text--light .selected-projects__project .faux-link {
    background-image: linear-gradient(transparent 50%, #FFFFFF 50%);
    color: #FFFFFF; }

/*================ Plyr video skin ================*/
/**
 * Large play button color
 */
.plyr__control--overlaid {
  background: rgba(0, 0, 0, 0.75); }

/**
 * Controls hover state color
 */
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: #000000; }

/**
 * Progress bar color
 */
.plyr--full-ui input[type=range] {
  color: rgba(0, 0, 0, 0.75); }

/**
 * Use site-wide typeface
 */
.plyr {
  font-family: sans-serif;
  /* 1 */
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.2; }
  .font-loaded .plyr {
    font-family: 'DB Tactic Medium', 'Helvetica Neue', 'Helvetica', 'Arial' sans-serif;
    /* 2 */ }

/**
 * Hide video until Plyr is initialised
 */
.no-touchevents .video--clicktoplay {
  opacity: 0; }
  .plyr .no-touchevents .video--clicktoplay {
    opacity: 1; }

.video iframe {
  opacity: 0; }

.video .js-reframe iframe {
  opacity: 1; }

.plyr {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  animation-duration: 0.5s; }

/**
 * Remove black background
 */
.plyr--video,
.plyr__video-wrapper,
.plyr__poster {
  background-color: transparent; }

/*================ DOS CREDIT ================*/
/**
 * Format link
 */
.dos-credit__link::after {
  content: '';
  display: inline-block;
  background-image: url("../img/dos.svg");
  background-size: contain;
  background-repeat: no-repeat;
  height: 1.5rem;
  width: 1.5rem;
  position: absolute;
  top: -0.1875rem;
  right: -1.625rem; }

/**
 * Position credit
 */
.dos-credit {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 1.25rem; }
  @media only screen and (min-width: 540px) {
    .dos-credit {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem;
      padding-left: 2.5rem; } }

/**
 * Remove bottom margin from credit
 */
.dos-credit__text {
  margin-bottom: 0; }

/**
 * Use content to push footer down
 */
.info-page {
  min-height: calc(100vh - 3.5rem); }
  @media only screen and (min-width: 540px) {
    .info-page {
      min-height: calc(100vh - 6rem); } }
