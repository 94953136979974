/*================ Layout ================*/

/**
 * Min height
 */
.site-inner-wrapper {
	min-height: 100vh;
}

/**
 * Prevent overflow
 */
.site-inner-wrapper {
	width: 100vw;
	overflow: hidden;
}
