/*================ Links ================*/

/**
 * Link formatting
 */
a,
a:link,
a:visited,
a:active,
a:focus,
.faux-link {
	text-decoration: none;
	background-position: 0 1em;
	background-size: 100% 0.125rem;
	background-repeat: no-repeat;
	background-image: linear-gradient(transparent 50%, $col--link-underline 50%);
	color: $col--link-text;
	cursor: pointer;
}

a:hover,
.faux-link:hover,
.faux-link-hover {
	background-image: linear-gradient(transparent 50%, $col--link-underline-hover 50%);
	color: $col--link-text-hover;
}

/**
 * Add click effect
 */
a {
	position: relative;
	top: 0;

	&:active {
		top: 0.0625rem;
	}
}

/**
 * Remove underline
 */
.link-no-underline {
  &,
  &:link,
  &:visited,
  &:hover,
  &:active,
  &:focus {
    background-image: none !important;
  }
}
