// -----------------------------------------------------------------------------
// This file defines the colors used within the design system.
// -----------------------------------------------------------------------------

// Core variables (color-focused)
$col--black: #000000;
$col--white: #FFFFFF;
$col--black-tint-dark: rgba(0, 0, 0, 0.75);
$col--black-tint-mid: rgba(0, 0, 0, 0.25);
$col--black-tint-light: rgba(0, 0, 0, 0.03);
