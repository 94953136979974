/*================ Text pages ================*/

/**
 * Set outer margins
 */
.text-page {
	@include outer-margins;
}

/**
 * Format text
 */
.text-page {
	max-width: 33.75rem;
}
