// -----------------------------------------------------------------------------
// This file defines the font sizes used within the design system.
// -----------------------------------------------------------------------------

// Font sizes
$fs-s0: 0.875rem;   // 14px
$fs-s1: 1.125rem;   // 18px

@mixin size--0 {
  font-size: $fs-s0;
}

@mixin size--1 {
  font-size: $fs-s1;
}
