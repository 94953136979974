/*================ Text color based on bg color ================*/

/**
 * Set text color
 */
.text--light {
	.site-header,
	.project,
	.selected-projects__project {
		color: $col--type-light;

		a,
		a:link,
		a:visited,
		a:active,
		a:focus,
		.faux-link {
			background-image: linear-gradient(transparent 50%, $col--type-light 50%);
			color: $col--type-light;
		}
	}
}
