// -----------------------------------------------------------------------------
// General mixins to be used by other scss files.
// -----------------------------------------------------------------------------

// Clearfix to ensure parents clear their floated children
@mixin clearfix() {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
  // sass-lint:disable
  *zoom: 1;
}

// Define media query rules using the variables defined in
// design-system/grid-settings
@mixin media-query($media-query) {
  $breakpoint-found: false;

  @each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);

    @if $media-query == $name and $declaration {
      $breakpoint-found: true;

      @media only screen and #{$declaration} {
        @content;
      }
    }
  }

  @if $breakpoint-found == false {
    @warn 'Breakpoint "#{$media-query}" does not exist';
  }
}

// Responsive show/hide helper
@mixin responsive-display-helper($breakpoint: '') {
  // sass-lint:disable no-important
  .#{$breakpoint}show {
    display: block !important;
  }

  .#{$breakpoint}hide {
    display: none !important;
  }
}

// Responsive text alignment helper
@mixin responsive-text-align-helper($breakpoint: '') {
  // sass-lint:disable no-important
  .#{$breakpoint}text-left {
    text-align: left !important;
  }

  .#{$breakpoint}text-right {
    text-align: right !important;
  }

  .#{$breakpoint}text-center {
    text-align: center !important;
  }
}

// Visually show/hide helpers
@mixin visually-hidden() {
  // sass-lint:disable no-important
  position: absolute !important;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

@mixin visually-shown($position: inherit) {
  // sass-lint:disable no-important
  position: $position !important;
  overflow: auto;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
}

// Vertically center element
@mixin vertical-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
