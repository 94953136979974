/*================ Homepage ================*/

/**
 * Format selected projects container
 */
.selected-projects {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/**
 * Format project slides
 */
.selected-projects__project {
	height: 100%;
}

.selected-projects__project-link {
	display: block;
	position: relative;
	height: 100%;
	width: 100%;
}

/**
 * Set size of media
 */
.selected-projects__project-media > .image,
.selected-projects__project-media > .video {
	height: auto;
	width: calc(100vw - (#{$spacer-md} * 2));
	max-height: 80vh;
}

@include media-query($medium-up) {
	.selected-projects__project-media--small > .image,
	.selected-projects__project-media--small > .video {
		width: 30vw;
	}

	.selected-projects__project-media--medium > .image,
	.selected-projects__project-media--medium > .video {
		width: 50vw;
	}

	.selected-projects__project-media--large > .image,
	.selected-projects__project-media--large > .video {
		width: 70vw;
	}
}

/**
 * Centre the slides
 */
.selected-projects__project-media > .image,
.selected-projects__project-media > .video {
	position: absolute;
	top: 49%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/**
 * Position project text
 */
.selected-projects__project-info {
	position: absolute;
	z-index: 2;
	width: calc(100% - (#{$spacer-md} * 2));
	bottom: $spacer-md;
	left: $spacer-md;

	// @supports(padding: max(0px)) {
	// 	bottom: unquote('max(#{$spacer-md}, env(safe-area-inset-bottom))');
	// 	left: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// }

	@include media-query($medium-up) {
		bottom: $spacer-lg;
		left: $spacer-lg;

		// @supports(padding: max(0px)) {
		// 	bottom: unquote('max(#{$spacer-lg}, env(safe-area-inset-bottom))');
		// 	left: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// }
	}
}

/**
 * Remove margins from project text
 */
.selected-projects__project-title,
.selected-projects__project-cta {
	margin-bottom: 0;
}

/**
 * Format faux link hover state
 */
.selected-projects__project-link:hover .faux-link {
	@extend .faux-link-hover;
}

/**
 * Remove link flash from iOS browsers
 */
.selected-projects__project-link {
	-webkit-tap-highlight-color: transparent;
}
