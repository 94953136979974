/*================ All projects  ================*/

/**
 * Set outer margins
 */
.project-index-page {
	@include outer-margins;
}

/**
 * Set background color
 */
.project-index-page {
	background-color: $col--background-site-wrapper;
}

/**
 * Sort clearing of rows
 */
.archive__project:nth-child(2n+1) {
	clear: both;
}

@include media-query($medium-up) {

	.archive__project:nth-child(2n+1) {
		clear: none;
	}

	.archive__project:nth-child(3n+1) {
		clear: both;
	}
}

@include media-query($large-up) {

	.archive__project:nth-child(3n+1) {
		clear: none;
	}

	.archive__project:nth-child(4n+1) {
		clear: both;
	}
}

/**
 * Space before project title
 */
.archive__project-title {
	@include p-t-spacer-($spacer-sm);
}

/**
 * Space between rows
 */
.archive__project {
	@include m-b-spacer-($spacer-md);

	@include media-query($large-up) {
		@include m-b-spacer-($spacer-lg);
	}
}

/**
 * Format faux link hover state
 */
.archive__project-link:hover .faux-link {
	@extend .faux-link-hover;
}

/**
 * Layout text only grid
 */
.archive--text-only {
	@include p-t-spacer-($spacer-lg);
	padding-bottom: 0;

	@include p-x-spacer-($spacer-md);
	// @supports(padding: max(0px)) {
	// 	padding-right: unquote('max(#{$spacer-md}, env(safe-area-inset-right))');
	// 	padding-left: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// }
	@include media-query($medium-up) {
		@include p-x-spacer-($spacer-lg);
		// @supports(padding: max(0px)) {
		// 	padding-right: unquote('max(#{$spacer-lg}, env(safe-area-inset-right))');
		// 	padding-left: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// }
	}
}
