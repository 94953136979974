// -----------------------------------------------------------------------------
// This file defines the spacing used within the design system.
// -----------------------------------------------------------------------------

// ----------------------------------------
// Absolute horizontal and vertical spacing
// ----------------------------------------

$base: 0.625rem; // 10px

$spacer-xs: $base * .5;     // 5px
$spacer-sm: $base;          // 10px
$spacer-md: $base * 2;      // 20px
$spacer-lg: $base * 4;      // 40px
$spacer-xl: $base * 8;      // 80px
$spacer-xxl: $base * 16;      // 160px

$sizes: (
	xs $spacer-xs,
	sm $spacer-sm,
	md $spacer-md,
	lg $spacer-lg,
	xl $spacer-xl,
	xxl $spacer-xxl,
);

// margin
@each $size in $sizes {
	$name: nth($sizes, 1);
	$value: nth($sizes, 2);

	.m-t-spacer-#{$name} {
		margin-top: $value;
	}
	.m-b-spacer-#{$name} {
		margin-bottom: $value;
	}
	.m-l-spacer-#{$name} {
		margin-left: $value;
	}
	.m-r-spacer-#{$name} {
		margin-right: $value;
	}
	.m-x-spacer-#{$name} {
		margin-right: $value;
		margin-left: $value;
	}
	.m-y-spacer-#{$name} {
		margin-top: $value;
		margin-bottom: $value;
	}
}

// padding
@each $size in $sizes {
	$name: nth($sizes, 1);
	$value: nth($sizes, 2);

	.p-t-spacer-#{$name} {
		padding-top: $value;
	}
	.p-b-spacer-#{$name} {
		padding-bottom: $value;
	}
	.p-l-spacer-#{$name} {
		padding-left: $value;
	}
	.p-r-spacer-#{$name} {
		padding-right: $value;
	}
	.p-x-spacer-#{$name} {
		padding-right: $value;
		padding-left: $value;
	}
	.p-y-spacer-#{$name} {
		padding-top: $value;
		padding-bottom: $value;
	}
}

// Mixins for the spacers.
// usage: @include m-t-spacer-($spacer-md);

@mixin m-t-spacer-($value) {
	margin-top: $value;
}
@mixin m-b-spacer-($value) {
	margin-bottom: $value;
}
@mixin m-l-spacer-($value) {
	margin-left: $value;
}
@mixin m-r-spacer-($value) {
	margin-right: $value;
}
@mixin m-x-spacer-($value) {
	margin-left: $value;
	margin-right: $value;
}
@mixin m-y-spacer-($value) {
	margin-top: $value;
	margin-bottom: $value;
}

@mixin p-t-spacer-($value) {
	padding-top: $value;
}
@mixin p-b-spacer-($value) {
	padding-bottom: $value;
}
@mixin p-l-spacer-($value) {
	padding-left: $value;
}
@mixin p-r-spacer-($value) {
	padding-right: $value;
}
@mixin p-x-spacer-($value) {
	padding-left: $value;
	padding-right: $value;
}
@mixin p-y-spacer-($value) {
	padding-top: $value;
	padding-bottom: $value;
}
