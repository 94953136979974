/*================ View all projects link ================*/

/**
 * Horizontal padding
 */
.view-archive__link {
	padding-right: $spacer-md;
	padding-left: $spacer-md;

	// @supports(padding: max(0px)) {
	// 	padding-right: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// 	padding-left: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// }

	@include media-query($medium-up) {
		padding-right: $spacer-lg;
		padding-left: $spacer-lg;

		// @supports(padding: max(0px)) {
		// 	padding-right: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// 	padding-left: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// }
	}
}

/**
 * Vertical padding
 */
.view-archive__link {
	@include p-y-spacer-($spacer-xl);
}

/**
 * Make link cover the full area
 */
.view-archive__link {
	display: block;
}

/**
 * Format faux link hover state
 */
.view-archive__link:hover .faux-link {
	@extend .faux-link-hover;
}

/**
 * Set hover state on whole link
 */
.view-archive__link:hover {
	@include transition--(background-color);
	background-color: $col--background-projects-link;
}
