/*================ Helper Classes ================*/
.clearfix {
  @include clearfix();
}

.visually-hidden {
  @include visually-hidden();
}

.hidden {
  display: none;
}

.js-focus-hidden:focus {
  outline: none;
}

.label-hidden {
  @include visually-hidden();

  // No placeholders, so force show labels
  .no-placeholder & {
    @include visually-shown();
  }
}

.visually-shown {
  @include visually-shown();
}

// Only show when JS is not supported
.no-js:not(html) {
  display: none;

  .no-js & {
    display: block;
  }
}

// Only show when JS is supported
.js {
  .no-js & {
    display: none;
  }
}

// Text alignment
.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

// Background position alignment
.bg-left {
  background-position-x: left;
}

.bg-center {
  background-position-x: center;
}

.bg-right {
  background-position-x: right;
}

.bg-top {
  background-position-y: top;
}

.bg-middle {
  background-position-y: center;
}

.bg-bottom {
  background-position-y: bottom;
}

// Disable links and elements
.disabled {
  opacity: 0.5;
}

a.disabled {
  cursor: not-allowed;
}

// Prevent scrolling
.no-scroll {
  overflow: hidden;
}

// Clear floats
.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}
