/*================ Site header ================*/

/**
 * Positioning
 */
.site-header {
	position: fixed;
	left: 0;
	width: 100%;
	z-index: 2;
	top: $spacer-md;

	// @supports(padding: max(0px)) {
	// 	top: unquote('max(#{$spacer-md}, env(safe-area-inset-top))');
	// }

	@include media-query($medium-up) {
		top: $spacer-lg;

		// @supports(padding: max(0px)) {
		// 	top: unquote('max(#{$spacer-lg}, env(safe-area-inset-top))');
		// }
	}
}

.site-header__name {
	position: absolute;
	left: $spacer-md;

	// @supports(padding: max(0px)) {
	// 	left: unquote('max(#{$spacer-md}, env(safe-area-inset-left))');
	// }

	@include media-query($medium-up) {
		left: $spacer-lg;

		// @supports(padding: max(0px)) {
		// 	left: unquote('max(#{$spacer-lg}, env(safe-area-inset-left))');
		// }
	}
}

.site-header__nav {
	position: absolute;
	right: $spacer-md;

	// @supports(padding: max(0px)) {
	// 	right: unquote('max(#{$spacer-md}, env(safe-area-inset-right))');
	// }

	@include media-query($medium-up) {
		right: $spacer-lg;

		// @supports(padding: max(0px)) {
		// 	right: unquote('max(#{$spacer-lg}, env(safe-area-inset-right))');
		// }
	}
}

/**
 * Increase hit area on links
 */
.site-header__link {
	padding-top: 1em;
	padding-bottom: 0.5em;
	background-position: 0 2em !important;
}

/**
 * Remove bottom margins
 */
.site-header h1,
.site-header p {
	margin-bottom: 0;
}

/**
 * Format list
 */
.site-header__nav-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

.site-header__nav-list-item {
	display: inline;
	margin-left: 0.75rem;

	&:first-of-type {
		margin-left: 0;
	}
}
