/*================ Transitions ================*/

/**
 * Define initial load transition
 */
.new-session .site-header,
.new-session .page-content {
	opacity: 0;
	animation-name: fadeIn;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	animation-duration: 0.5s;
}

/**
 * Define between pages transition
 */
.fade-in .page-content {
	opacity: 0;
	animation-name: fadeIn;
	animation-fill-mode: forwards;
	animation-delay: 0.5s;
	animation-duration: 0.5s;
}

.fade-out .page-content {
	animation-name: fadeOut !important;
	animation-fill-mode: forwards;
	animation-delay: 0s;
	animation-duration: 0.5s;
}
